var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "8"
    }
  }, [_c('b-card', [_c('table', {
    staticClass: "w-full table"
  }, [_c('tr', [_c('td', [_vm._v("No. Disposal")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.disposal.no))])]), _c('tr', [_c('td', [_vm._v("Jenis Disposal")]), _c('td', [_vm._v(":")]), _c('th', [_c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(" " + _vm._s(_vm.jenisDisposal) + " ")])], 1)]), _c('tr', [_c('td', [_vm._v("Tanggal")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.disposal.tanggal))])]), _c('tr', [_c('td', [_vm._v("Aset")]), _c('td', [_vm._v(":")]), _c('th', [_vm.disposal.penyimpanan && _vm.disposal.penyimpanan.asset ? _c('strong', [_vm._v(" " + _vm._s(_vm.disposal.penyimpanan.asset.nama) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Asset tidak ditemukan")])])]), _c('tr', [_c('td', [_vm._v("Jumlah Disposal")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s(_vm.disposal.jumlah) + " ")])]), _c('tr', [_c('td', [_vm._v("Satuan")]), _c('td', [_vm._v(":")]), _c('th', [_vm.disposal.penyimpanan && _vm.disposal.penyimpanan.asset && _vm.disposal.penyimpanan.asset.satuan ? _c('strong', [_vm._v(" " + _vm._s(_vm.disposal.penyimpanan.asset.satuan.satuan) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Satuan tidak ditemukan")])])]), _c('tr', [_c('td', [_vm._v("Keterangan")]), _c('td', [_vm._v(":")]), _c('th', [_vm._v(_vm._s(_vm.disposal.keterangan))])])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }